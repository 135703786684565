import React from 'react';
import styled from 'styled-components';
import {Button, Input, message, Modal} from 'antd';
import {EyeOutlined} from '@ant-design/icons';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import {navigate} from 'gatsby';
import URL_PATH from '../../UrlPath';
import * as Icon from '../../components/Icon';
import ModalContent from '../../components/ModalContent';
import FileUploadPanelBtn from '../../components/FileUploadPanelBtn';
import DashboardProductCatFileUpdateButton from '../../components/DashboardProductCatFileUpdateButton';

function CategoriesPage(props) {
  const app = React.useContext(AppContext.Context);
  const [categories, setCategories] = React.useState(null);
  const [createdCat, setCreatedCat] = React.useState({name: '', image: ''});

  const _updateCategories = React.useCallback(async () => {
    try {
      let cats = await app.actions.getCategories();
      setCategories(cats);
    } catch (err) {
      //
      message.error('無法取得商品分類資料！');
    }
  }, [app.actions]);

  React.useEffect(() => {
    const _fetchData = async () => {
      try {
        AppActions.setLoading(true);
        await _updateCategories();
        AppActions.setLoading(false);
      } catch (err) {
        //
      }
    };
    _fetchData();
  }, [_updateCategories]);

  const _onOrderChange = React.useCallback(
    (fromIndex) => (toIndex) => {
      let _currentCatChildren = categories.children;
      let _movingCat = _currentCatChildren[fromIndex];
      let _pickOutMovingCats = _currentCatChildren.filter(
        (c) => c.key !== _movingCat.key,
      );
      let _resultCatChildren = [
        ..._pickOutMovingCats.slice(0, toIndex),
        _movingCat,
        ..._pickOutMovingCats.slice(toIndex),
      ];

      setCategories({
        ...categories,
        children: _resultCatChildren,
      });
    },
    [categories],
  );

  const _updateCat = React.useCallback(
    (index) => (data) => {
      let _updatedCatChildren = [...categories.children];
      _updatedCatChildren[index] = data;
      setCategories({
        ...categories,
        children: _updatedCatChildren,
      });
    },
    [categories],
  );

  const _onDelete = React.useCallback(
    (idx) => () => {
      const _confirmDelete = () => {
        let _updatedCatChildren = [
          ...categories.children.slice(0, idx),
          ...categories.children.slice(idx + 1),
        ];
        setCategories({
          ...categories,
          children: _updatedCatChildren,
        });
      };

      Modal.confirm({
        title: `是否確定刪除類別：${categories.children[idx].name}？`,
        content: `刪除後請記得按下右下方 SUBMIT 按鈕完成儲存`,
        cancelText: '取消',
        okText: '刪除',
        onOk: _confirmDelete,
      });
    },
    [categories],
  );

  const _onCreateCat = React.useCallback(() => {
    if (!createdCat.name) {
      message.warn('請填入類別名稱！');
      return;
    }
    const _randomKey = `${Math.floor(Math.random() * 1000)}`;
    let _newCat = {
      ...createdCat,
      key: _randomKey,
    };
    let _updatedCatChildren = [...categories.children, _newCat];

    setCreatedCat({
      name: '',
      image: '',
    });
    setCategories({
      ...categories,
      children: _updatedCatChildren,
    });
  }, [categories, createdCat]);

  const _onSave = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      let resp = await app.actions.staffUpdateCategory(categories);
      setCategories(resp);
      message.success('已成功更新商品類別！請記得按下更新按鈕更新網站');
    } catch (err) {
      message.error('無法更新商品類別！');
    }
    AppActions.setLoading(false);
  }, [categories]);

  if (!categories) {
    return null;
  }

  return (
    <Wrapper>
      <h2>商品類別</h2>
      <div className="content">
        <div
          className="hint"
          style={{marginBottom: 10, color: 'var(--color-red)'}}>
          注意：若子類別要顯示為上架，父類別必須設定為上架狀態
        </div>
        {categories.children.map((c, idx, arr) => (
          <CategoryItem
            key={idx}
            index={idx}
            layer={1}
            category={c}
            siblingCats={arr}
            updateCat={_updateCat(idx)}
            onOrderChange={_onOrderChange(idx)}
            onDelete={_onDelete(idx)}
          />
        ))}

        <div className="blank-cat">
          <Input
            value={createdCat.name}
            placeholder={'類別名稱'}
            onChange={(e) =>
              setCreatedCat({...createdCat, name: e.target.value})
            }
          />
          <Input
            value={createdCat.image}
            placeholder={'類別主圖'}
            onChange={(e) =>
              setCreatedCat({...createdCat, image: e.target.value})
            }
            style={{marginLeft: 10}}
          />
          <Button onClick={_onCreateCat} style={{marginLeft: 10}}>
            新增
          </Button>
        </div>
        <div className="actions">
          <DashboardProductCatFileUpdateButton style={{marginRight: 10}} />
          <Button type={'primary'} onClick={_onSave}>
            儲存
          </Button>
        </div>
      </div>

      <div className="floating-actions">
        <FileUploadPanelBtn />
      </div>
    </Wrapper>
  );
}

const ACTIONS = {
  MOVE_UP: 'MOVE_UP',
  MOVE_DOWN: 'MOVE_DOWN',
};

function CategoryItem(props) {
  const {
    category,
    siblingCats = [],
    index,
    layer = 1,
    onOrderChange,
    onDelete = () => 0,
    updateCat = () => 0,
  } = props;

  const [createdChildCat, setCreatedChildCat] = React.useState({
    name: '',
    image: '',
  });

  const _allowMoveUp = React.useMemo(() => {
    return index !== 0;
  }, [index]);

  const _allowMoveDown = React.useMemo(() => {
    return index < siblingCats.length - 1;
  }, [index, siblingCats]);

  const _onOrderChange = React.useCallback(
    (action) => {
      if (action === ACTIONS.MOVE_UP) {
        _allowMoveUp && onOrderChange(index - 1);
      } else if (action === ACTIONS.MOVE_DOWN) {
        _allowMoveDown && onOrderChange(index + 1);
      }
    },
    [index, _allowMoveUp, _allowMoveDown, onOrderChange],
  );

  const _onChildOrderChange = React.useCallback(
    (fromIndex) => (toIndex) => {
      let _currentCatChildren = category.children;
      let _movingCat = _currentCatChildren[fromIndex];
      let _pickOutMovingCats = _currentCatChildren.filter(
        (c) => c.key !== _movingCat.key,
      );
      let _resultCatChildren = [
        ..._pickOutMovingCats.slice(0, toIndex),
        _movingCat,
        ..._pickOutMovingCats.slice(toIndex),
      ];

      updateCat({
        ...category,
        children: _resultCatChildren,
      });
    },
    [updateCat],
  );

  const _onTitleChange = React.useCallback(
    (e) => {
      updateCat({
        ...category,
        name: e.target.value || category.name, // disallow for blank title
      });
    },
    [updateCat, category],
  );

  const _updateChildCat = React.useCallback(
    (index) => (data) => {
      let _updatedCatChildren = [...category.children];
      _updatedCatChildren[index] = data;
      updateCat({
        ...category,
        children: _updatedCatChildren,
      });
    },
    [category, updateCat],
  );

  const _onChildDelete = React.useCallback(
    (idx) => () => {
      const _confirmDelete = () => {
        let _updatedCatChildren = [
          ...category.children.slice(0, idx),
          ...category.children.slice(idx + 1),
        ];
        updateCat({
          ...category,
          children: _updatedCatChildren,
        });
      };

      Modal.confirm({
        title: `是否確定刪除類別：${category.children[idx].name}？`,
        content: `刪除後請記得按下右下方 SUBMIT 按鈕完成儲存`,
        cancelText: '取消',
        okText: '刪除',
        onOk: _confirmDelete,
      });
    },
    [category, updateCat],
  );

  const _onDelete = React.useCallback(() => {
    onDelete();
  }, [onDelete]);

  const _onCreateChildCat = React.useCallback(() => {
    if (!createdChildCat.name) {
      message.warn('請填入類別名稱！');
      return;
    }
    const _randomKey = `${category.key}__${Math.floor(Math.random() * 1000)}`;
    let _newCat = {
      ...createdChildCat,
      key: _randomKey,
    };
    let _updatedCatChildren = [...(category.children || []), _newCat];

    setCreatedChildCat({
      name: '',
      image: '',
    });
    updateCat({
      ...category,
      children: _updatedCatChildren,
    });
  }, [createdChildCat, category, updateCat]);

  const _onStatusChange = React.useCallback(() => {
    updateCat({
      ...category,
      published: !category.published,
    });
  }, [category, updateCat]);

  const _openViewCatBannerImgModal = React.useCallback(() => {
    AppActions.setModal(
      <ModalContent title={`${category.name} 類別主圖`}>
        <CatBannerModalContent category={category} updateCat={updateCat} />
      </ModalContent>,
    );
  }, [category]);

  return (
    <CategoryItemWrapper>
      <div className="item">
        <div className="title">
          <Input value={category.name} onChange={_onTitleChange} />
        </div>
        <div className="buttons">
          <Button
            icon={<EyeOutlined />}
            onClick={_openViewCatBannerImgModal}
            style={{marginRight: 10}}>
            主圖
          </Button>

          <div className="btn" onClick={() => _onOrderChange(ACTIONS.MOVE_UP)}>
            <Icon.KeyboardArrowUp size={24} />
          </div>
          <div
            className="btn"
            style={{background: category.published ? '#b7eb8f' : '#e6e6e6'}}
            onClick={() => _onStatusChange()}>
            {category.published ? '上架中' : '下架中'}
          </div>
          <div
            className="btn"
            onClick={() => _onOrderChange(ACTIONS.MOVE_DOWN)}>
            <Icon.KeyboardArrowDown size={24} />
          </div>
          <div className="btn" onClick={() => _onDelete()}>
            刪除
          </div>
        </div>
      </div>

      <div className="children">
        {category.children && (
          <div className="sub-cats">
            {category.children.map((child, idx, arr) => (
              <CategoryItem
                key={idx}
                index={idx}
                layer={layer + 1}
                category={child}
                siblingCats={arr}
                updateCat={_updateChildCat(idx)}
                onDelete={_onChildDelete(idx)}
                onOrderChange={_onChildOrderChange(idx)}
              />
            ))}
          </div>
        )}

        {layer < 2 && ( // leruban only allow for two layers cat
          <div className="blank-cat">
            <Input
              value={createdChildCat.name}
              placeholder={'類別名稱'}
              onChange={(e) =>
                setCreatedChildCat({...createdChildCat, name: e.target.value})
              }
            />
            <Input
              value={createdChildCat.image}
              placeholder={'類別主圖'}
              onChange={(e) =>
                setCreatedChildCat({...createdChildCat, image: e.target.value})
              }
              style={{marginLeft: 10}}
            />
            <Button onClick={_onCreateChildCat} style={{marginLeft: 10}}>
              新增
            </Button>
          </div>
        )}
      </div>
    </CategoryItemWrapper>
  );
}

const CatBannerModalContent = (props) => {
  const {category, updateCat} = props;
  const [tempCatBanner, setTempCatBanner] = React.useState(
    category.image || '',
  );
  const [hasEdit, setHasEdit] = React.useState(false);

  return (
    <div>
      {category.image && <img src={category.image} width={'100%'} />}

      <Input
        value={tempCatBanner}
        placeholder={'類別主圖'}
        onChange={(e) => {
          setHasEdit(true);
          setTempCatBanner(e.target.value);
        }}
        style={{marginTop: 10}}
      />

      <p style={{marginTop: 20, marginBottom: 10, textAlign: 'right'}}>
        {hasEdit &&
          `* 此商品圖狀態為暫存。按下確認後，需點選右下方「SUBMIT」商品圖才會完成更新`}
      </p>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button
          style={{marginRight: 10}}
          onClick={() => AppActions.setModal(null)}>
          取消
        </Button>
        <Button
          onClick={() => {
            if (hasEdit) {
              updateCat({...category, image: tempCatBanner});
            }
            AppActions.setModal(null);
          }}>
          確認
        </Button>
      </div>
    </div>
  );
};

const CategoryItemWrapper = styled.div`
  margin-bottom: 10px;
  & > .item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    & > .title {
      margin-right: 10px;
    }

    & > .buttons {
      display: flex;

      & > .btn {
        cursor: pointer;
        padding: 5px;
        margin-right: 10px;
        background: #e6e6e6;
      }
    }
  }

  & > .children {
    padding-left: 40px;

    & > .sub-cats {
    }

    & > .blank-cat {
      display: flex;
    }
  }
`;

const Wrapper = styled.div`
  padding: 20px 20px 100px;
  & > h2 {
    margin-bottom: 16px;
  }

  & > .content {
    & > .blank-cat {
      display: flex;
    }

    & > .actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;
    }
  }

  & .floating-actions {
    padding: 10px;
    position: fixed;
    right: 10px;
    bottom: 10px;
    background-color: #fff;
    z-index: 10;
    box-shadow: 0px 0px 10px #cecece;
    flex-direction: column;
    display: flex;
  }
`;

export default CategoriesPage;
